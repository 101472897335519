body {
  margin: 0;
  background: #222;
  padding-top: 60px;
  padding-bottom: 60px;
  font-family: 'Work Sans', sans-serif;
  font-weight: 800;
  width: 100%;
  height: 100%;
  display: table;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
